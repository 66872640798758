<template>
  <div>
    <input v-model="prompt" type="text" maxlength="200">
    <button @click="getReply" :disabled="prompt.length > 200">Send</button>
    <div v-if="reply">{{ reply }}</div>
  </div>
</template>

<script>
import { chat } from '@/api.js';

export default {
  data() {
    return {
      prompt: '',
      reply: '',
    }
  },
  methods: {
    async getReply() {
      if (this.prompt.length > 200) {
        alert('Prompt is too long. Please limit your input to 200 characters.');
        return;
      }
      
      const res = await chat.chatWithGPT({ prompt: this.prompt });
      if (res.status === 200) {
        console.log(res.data)
        this.reply = res.data.content;
      } else {
        // Error handling
        console.error('Failed to chat with GPT:', res);
      }
    }
  }
}
</script>
