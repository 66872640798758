<template>
<!-- eslint-disable -->
	<div class="mt-4">
		<h1>体重を記録</h1>
		<v-row class="ml-4 my-6">
			<v-col cols="9">
				<DatePicker @changeDate="setDate"/>
			</v-col>
		</v-row>
		<WeightRecorder @record="record"/>
	</div>
</template>

<script>
/*eslint-disable */
import * as api from '@/api.js'
import DatePicker from '@/components/DatePicker.vue'
import WeightRecorder from '@/components/WeightRecorder.vue'
export default {
	name: 'Weight',
	components: { DatePicker, WeightRecorder },
	data () {
		return {
            weight: 60.0,
    		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		}
	},
	methods: {
		load() {
			this.weight = this.$store.getters.loginInfo.weight
		},
		setDate(date) {
			this.date = date
		},
		record(weight){
			api.weight.record({userId: this.$store.getters.loginInfo.userId, weight: weight, date: this.date}).then((res) => {
                switch(res.status) {
                    case 200:
						this.pop('', res.data)
                        break
                    case 500:
						this.errorFlg = true
						this.pop('エラー', res.data.error[0])
                        break
                }
			}).finally()
		},
	},
	created(){
		this.load()
	},
	computed: {
	}
}
</script>
<style>
.v-menu__content {
    left: 42px!important;
}
.v-btn__content{
	padding-right: 4px;
}
</style>