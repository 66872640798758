<template>
    <div>
        <h1 class="text-center">{{ weight.toFixed(1) }} kg</h1>
        <v-row class="my-12">
            <v-spacer></v-spacer>
            <v-btn class="mx-4" @click="minus()">
                <v-icon size=25>mdi-minus</v-icon>
            </v-btn>
            <v-btn style="display: block; width: 150px;" type="submit" color="primary" @click="record()"><v-icon size=20 class="pr-2">mdi-pencil</v-icon>記録</v-btn>
            <v-btn class="mx-4" @click="plus()">
                <v-icon size=25>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'WeightRecorder',
	props: {
		weight: {
			default: 60.0
		},
	},
	data () {
		return {
		}
	},
	methods: {
		load() {
		},
        plus(){
            this.weight += 0.1
        },
        minus(){
            this.weight -= 0.1
        },
        record(){
            this.$emit('record', this.weight)
        },
    },
	created(){
	},
}
</script>